import { API_URL } from '../utils/config';
export const getAllArticles = () => {
    return fetch(`${API_URL}/api/articles?populate=*`)
        .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    });
};
export const getArticleById = (id) => {
    return fetch(`${API_URL}/api/articles/${id}?[populate][1]=editor&populate[0]=mainPhoto 
        &populate[2]=editor.photo&[populate][2]=categories
        &populate[3]=labels&populate[4]=basicSeo`)
        .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    });
};
export const getAllArticlesWithEditorPhoto = () => {
    return fetch(`${API_URL}/api/articles?[populate][1]=editor&populate[0]=mainPhoto &populate[2]=editor.photo&[populate][2]=categories&sort=publishedAt:desc`)
        .then(response => response.json());
};
export const getAllArticlesPageWise = (pageNo, pageSize) => {
    return fetch(`${API_URL}/api/articles?[populate][1]=editor&populate[0]=mainPhoto &populate[2]=editor.photo&[populate][2]=categories&sort=publishedAt:desc&&pagination[pageSize]=${pageSize}&pagination[page]=${pageNo}`)
        .then(response => response.json());
};
export const getAllFaqs = () => {
    return fetch(`${API_URL}/api/faqs?populate=*`)
        .then(response => response.json());
};
export const getAllEditors = () => {
    return fetch(`${API_URL}/api/editors?populate=*`)
        .then(response => response.json());
};
export const getAllSuppliers = () => {
    return fetch(`${API_URL}/api/suppliers`)
        .then(response => response.json());
};
export const getInstructionsBySupplier = (id) => {
    return fetch(`${API_URL}/api/supplier-instructions/${id}?populate=*`)
        .then(response => response.json());
};
export const getAllCategories = () => {
    return fetch(`${API_URL}/api/categories?populate=*`)
        .then(response => response.json());
};
