import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom'; // Ensure React Router is used for navigation
import { getAllArticles } from '../API';
const Carousel = ({ currentArticleId }) => {
    const [latestArticles, setLatestArticles] = useState([]);
    const carouselRef = useRef(null);
    // Scroll to the left (previous article)
    const scrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({
                left: -carouselRef.current.offsetWidth / 2, // Adjust to scroll by half the width of the container
                behavior: 'smooth',
            });
        }
    };
    // Scroll to the right (next article)
    const scrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({
                left: carouselRef.current.offsetWidth / 2, // Adjust to scroll by half the width of the container
                behavior: 'smooth',
            });
        }
    };
    useEffect(() => {
        // Assuming you have an API to get the latest articles
        getAllArticles().then((data) => {
            // Filter out the current article from the fetched articles
            const filteredArticles = data.data.filter(article => article.id !== currentArticleId);
            // Limit to 5 articles
            setLatestArticles(filteredArticles.slice(0, 5));
        });
    }, [currentArticleId]);
    if (latestArticles.length === 0)
        return null;
    return (_jsxs("div", { className: "pl-[15px] sm:pl-[260px] w-full  py-32 border-b-2", style: { backgroundColor: '#F5F5F7' }, children: [_jsxs("div", { className: "flex justify-between", children: [_jsx("h2", { className: "text-2xl font-bold px-8 py-8 mb-4", children: "Dal\u0161\u00ED \u010Dl\u00E1nky" }), _jsxs("div", { className: "flex px-8 py-8 space-x-2", children: [_jsx("button", { onClick: scrollLeft, className: "bg-red-500 text-white rounded-full w-14 h-14 p-3 z-10 text-2xl font-bold", children: "\u2190" }), _jsx("button", { onClick: scrollRight, className: "bg-red-500 text-white rounded-full w-14 h-14 p-3 z-10 text-2xl font-bold", children: "\u2192" })] })] }), _jsx("div", { ref: carouselRef, className: "flex space-x-4 top-6 overflow-hidden", style: { scrollBehavior: 'smooth' }, children: latestArticles.map((article) => (_jsx(Link, { to: `/articles/${article.id}`, className: "flex-shrink-0 w-[300px]", children: _jsxs("div", { className: "border rounded-lg p-4 shadow-md", children: [_jsx("img", { className: "w-full h-48 object-cover rounded-lg mb-2", src: `http://localhost:1337${article.attributes.mainPhoto.data.attributes.url}`, alt: article.attributes.headline }), _jsx("h3", { className: "text-lg font-semibold", children: article.attributes.headline })] }) }, article.id))) })] }));
};
export default Carousel;
