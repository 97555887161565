import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menubar, MenubarMenu, MenubarSeparator, } from "@/components/ui/menubar";
import '../index.css'; // Make sure this path is correct
const labels = ['Projects', 'Articles', 'Editors', 'Suppliers', 'FAQ'];
// Define the base URL
const baseUrl = '/pages';
// Create the menu items
const items1 = ['1', '2', '3', '4', '5'].map((key, index) => ({
    key: `${baseUrl}/${labels[index].toLowerCase().replace(' ', '-')}`, // Use dynamic URLs as keys
    label: labels[index],
}));
const NavBar = () => {
    //const [isMenuOpen, setIsMenuOpen] = useState(false);
    return (_jsxs("div", { className: "bg-navbar-blue h-[3rem] rounded-full shadow-lg flex flex-wrap items-center justify-between w-[92%] sm:w-[71%] sm:gap-x-24", children: [_jsxs("div", { className: "flex items-center", children: [_jsx("img", { src: "/assets/PorovnejLogo.svg", alt: "Logo", className: "sm:w-[9.625rem] sm:h-[1.5rem] px-[1.25rem] w-[8.68rem] h-[1.06rem]" }), " "] }), _jsxs("div", { className: "flex flex-grow sm:flex sm:flex-grow sm:justify-between ", children: [_jsx("div", { className: 'hidden items-center xl:flex xl:space-x-4 xl:w-fit xl:justify-between', children: _jsxs(Menubar, { className: "border-none", children: [_jsx(MenubarMenu, { children: items1.map((item) => (_jsx("a", { href: item.key, className: "block px-4 py-2 text-gray-200 text-[1rem]", children: item.label }, item.key))) }), _jsx(MenubarSeparator, {})] }) }), _jsxs("div", { className: "flex items-center  space-x-4 mr-[5px] ml-auto", children: [_jsxs("div", { className: "hidden sm:flex sm:flex-col  sm:items-center sm:space-y-1 text-white ", children: [_jsx("div", { className: "text-[14px] font-bold", children: "+420 227 272 100" }), _jsx("div", { className: "text-[10px] ", children: "PO-PA 8:00 - 17:00" })] }), _jsx("img", { src: "/assets/contact.svg", alt: "Contact", className: "w-[33px] h-[33px] sm:w-[33px] sm:h-[33px]" })] })] })] }));
};
export default NavBar;
