import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Header from '../components/Header';
import Footer from '../components/Footer';
import ArticleListingContent from '@/components/ArticleListingContent';
import { useParams } from 'react-router-dom';
const ArticleListingPage = () => {
    const { pageNoQP = "1" } = useParams();
    return (_jsxs("div", { className: "flex flex-col w-full min-h-screen", children: [_jsx(Header, {}), _jsx("div", { className: "relative z-10 flex-grow", children: _jsx("div", { children: _jsx(ArticleListingContent, { pageNoQP: Number.parseInt(pageNoQP) }) }) }), _jsx(Footer, {})] }));
};
export default ArticleListingPage;
